import {Injectable} from '@angular/core';
import {filter, Observable, Subject} from "rxjs";
import {Alert, AlertOptions, AlertType} from "../models/alert";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  protected userWantPushNotification : boolean = false;
  protected subscribeURL = "https://ntfy.espaceclient-parismedium-dev.staging.makeo.fr/topic-identifier-rvzkop852";
  protected eventSource = new EventSource(this.subscribeURL);
  protected events : [any];

  public initialize(){
    let userNotificationPreferences = {
      push : true,
      email : true,
      sms : false
    }
    // TODO : request to get user preference or retrieve user object with this info
    this.userWantPushNotification = userNotificationPreferences.push ? userNotificationPreferences.push : false;

    let promise = null;
    if(this.userWantPushNotification){
      let promise = Notification.requestPermission();
      if (!("Notification" in window)) {
        // Check if the browser supports notifications
        alert("This browser does not support desktop notification");
      }else {
        promise.then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');
          } else {
            console.log('Unable to get permission to notify.');
          }
        });
      }
    }
    return promise; // wait for permission
  }


  public listenEvents(){
    // Incoming events
    this.eventSource.onopen = () => {
      console.log("EventSource connected to " + this.subscribeURL);
    };
    this.eventSource.onerror = (e) => {
      console.log("EventSource error: Failed to connect to " + this.subscribeURL);
    };
    this.eventSource.onmessage = (e) => {
      console.log("Event received, data : ", e.data);
      this.events.push(e.data);
    };
  }


  public onNotification(notification : any): void {
    console.log("exec onNotification() : ", notification);
  }


  public testNotification() {
    if (Notification.permission === "granted") {
      const notification = new Notification("Hi there!");
    } else {
      console.log('No permission to notify.');
    }
  }








}
