import {Route} from "@angular/router";
import {LayoutComponent} from "./layout/layout.component";
import {NoAuthGuard} from "@makeo-packages/mkongusers";
import {initialDataResolver} from "./app.resolvers";

export const appRoutes: Route[] = [
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty'
    },
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {path: '', loadChildren: () => import('app/views/home/home.routes')},
    ]
  },
  {path: '', pathMatch: 'full', redirectTo: 'connexion'},
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'classy'
    },
    children: [
      // {path: 'search', loadChildren: () => import('app/views/search-voyants/search-voyants.routes')},
      {path: 'prendre-rdv', loadChildren: () => import('app/views/appointments/appointments.routes')},
      {path: 'voyance-privee', loadChildren: () => import('app/views/private/private-call.routes')},
      {path: 'audiotel', loadChildren: () => import('app/views/audiotel/audiotel.routes')},
      //{path: 'account', loadChildren: () => import('app/views/account/account.routes')},
      {path: 'mes-rdv', loadChildren: () => import('app/views/account/account-appointments.routes')},
      {path: 'factures', loadChildren: () => import('app/views/account/account-invoices.routes')},
      {path: 'parametres', loadChildren: () => import('app/views/account/account-parameters.routes')},
    ]
  },
];

// import { Route } from '@angular/router';
// import { initialDataResolver } from 'app/app.resolvers';
// import { AuthGuard } from 'app/core/auth/guards/auth.guard';
// import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
// import { LayoutComponent } from 'app/layout/layout.component';
//
// // @formatter:off
// /* eslint-disable max-len */
// /* eslint-disable @typescript-eslint/explicit-function-return-type */
// export const appRoutes: Route[] = [
//
//     // Redirect empty path to '/example'
//     {path: '', pathMatch : 'full', redirectTo: 'example'},
//
//     // Redirect signed-in user to the '/example'
//     //
//     // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
//     // path. Below is another redirection for that path to redirect the user to the desired
//     // location. This is a small convenience to keep all main routes together here on this file.
//     {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'example'},
//
//     // Auth routes for guests
//     {
//         path: '',
//         canActivate: [NoAuthGuard],
//         canActivateChild: [NoAuthGuard],
//         component: LayoutComponent,
//         data: {
//             layout: 'empty'
//         },
//         children: [
//             {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.routes')},
//             {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
//             {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
//             {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
//             {path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes')}
//         ]
//     },
//
//     // Auth routes for authenticated users
//     {
//         path: '',
//         canActivate: [AuthGuard],
//         canActivateChild: [AuthGuard],
//         component: LayoutComponent,
//         data: {
//             layout: 'empty'
//         },
//         children: [
//             {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
//             {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes')}
//         ]
//     },
//
//     // Landing routes
//     {
//         path: '',
//         component: LayoutComponent,
//         data: {
//             layout: 'empty'
//         },
//         children: [
//             {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
//         ]
//     },
//
//     // Admin routes
//     {
//         path: '',
//         canActivate: [AuthGuard],
//         canActivateChild: [AuthGuard],
//         component: LayoutComponent,
//         resolve: {
//             initialData: initialDataResolver
//         },
//         children: [
//             {path: 'example', loadChildren: () => import('app/modules/admin/example/example.routes')},
//         ]
//     }
// ];
