import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "./services/notification.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
})
export class AppComponent {

    /**
     * Constructor
     */
    constructor(
      private translateService: TranslateService,
      private notificationService: NotificationService) {
    }

  ngOnInit() {
    this.notificationService.initialize();
    this.notificationService.testNotification();
  }

}
