import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import {importProvidersFrom} from "@angular/core";
import {ServiceWorkerModule} from "@angular/service-worker";

bootstrapApplication(AppComponent, appConfig).catch((err) =>
    console.error(err)
);

importProvidersFrom(ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: true,
  // Register the ServiceWorker as soon as the application is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
}));
