import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandlerFn,
    HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import {DoubleAuthService} from "../../services/double-auth.service";
import { AuthUtils } from 'app/core/auth/auth.utils';
import { Observable, catchError, throwError } from 'rxjs';
import {environment} from "../../../environments/environment";
import {AlertService} from "../../services/alert.service";
import {ActivatedRoute, Router} from "@angular/router";

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const authInterceptor = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const authService =  inject(DoubleAuthService);
    const alertService = inject(AlertService);
    const router = inject(Router);

    // Clone the request object
    let newReq = req.clone();

    const nonAuthRoutes = [
      /^.*\/login_check$/g
    ];

    const nonAuthFrontRoute = [
      /^.*\/connexion$/g,
      /^.*\/inscription#.*$/g
    ]

    // Request
    //
    // If the access token didn't expire, add the Authorization header.
    // We won't add the Authorization header if the access token expired.
    // This will force the server to return a "401 Unauthorized" response
    // for the protected API routes which our response interceptor will
    // catch and delete the access token from the local storage while logging
    // the user out from the app.
    if (
        authService.accessToken &&
        !AuthUtils.isTokenExpired(authService.accessToken)
    ) {
        //console.log('core/auth/interceptor REQ_MODIF ' + newReq.url + ', add token header : ' + authService.accessToken);
        newReq = req.clone({
            headers: req.headers.set(
                'Authorization',
                'Bearer ' + authService.accessToken
            ),
        });
    }else{
      //console.log('core/auth/interceptor REQ_NO_MODIF ' + newReq.url);
    }


    // Response
    return next(newReq).pipe(
        catchError((error) => {

            // Catch "401 Unauthorized" responses
            if (error instanceof HttpErrorResponse && error.status === 401) {
              let isNonAuthRoute = nonAuthRoutes.find(reg => newReq.url.match(reg)) !== undefined;
              let isNonAuthFrontRoute = nonAuthFrontRoute.find(reg => router.url.match(reg)) !== undefined;
              if(!isNonAuthRoute && !isNonAuthFrontRoute) {

                  alertService.error("Vous n'êtes pas connecté. Retour à l'accueil.")
                  setTimeout(() => authService.signOut(), 1000)
                  // Sign out
                  // Reload the app
                  //location.reload();
                }
            }

            return throwError(error);
        })
    );
};
