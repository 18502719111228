export const environment = {
    production: false,
    apiAddress: 'https://api.espaceclient-parismedium-release.staging.makeo.fr/api',
    debounceTime: 1,
    refreshTokenTtl: 60,
    domain: 'localhost',
    patternPasswordValidator: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$\\-!%*?&])[A-Za-z\\d@$\\-!%*?&]{8,}$',
    descriptionPatternPasswordValidator: 'Votre mot de passe doit : comporter 8 caractères minimum et contenir 1 chiffre, 1 majuscule et 1 minuscule.',
    sentryEnable: false,
    version: "0.0.0"
};
